import setQueryParam from './functions/set-query-param';
import loadAndReplaceProductsFrame from './functions/load-and-replace-products-frame';

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('change', function(event) {
    if (event.target.dataset.role === 'products-order-select') {
      const sortSelect = event.target;
      const selectedOption = sortSelect.options[sortSelect.selectedIndex];

      let paramsString = window.location.search;
      paramsString = setQueryParam(paramsString, 'order', selectedOption.value);

      loadAndReplaceProductsFrame(
        sortSelect.dataset.target,
        window.location.pathname + paramsString
      );
    };
  });
})
