import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faThList } from "@fortawesome/free-solid-svg-icons/faThList";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons/faCartArrowDown";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUniversity } from "@fortawesome/free-solid-svg-icons/faUniversity";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";

import { faVk } from "@fortawesome/free-brands-svg-icons/faVk";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";

library.add(
  faSearch,
  faTh,
  faThList,
  faShoppingCart,
  faCartArrowDown,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faQuestionCircle,
  faPlus,
  faMinus,
  faTimes,
  faUser,
  faUniversity,
  faEnvelope,
  faPhone,
  faTruck,
  faUpload,
  faClock,
  faMapMarkerAlt,
  faCircleNotch,
  faVk,
  faFacebook,
  faInstagram
);
dom.watch();
