import IMask from 'imask';

document.addEventListener('DOMContentLoaded', () => {
  const phoneInputs = document.querySelectorAll('input[data-role="phone-input"]');

  phoneInputs.forEach(function(phoneInput) {
    IMask(
      phoneInput,
      {
        mask: '+{7} (000) 000-00-00',
        lazy: false
      }
    );
  })
})
