import Uppy from '@uppy/core';
import russianLocale from '@uppy/locales/lib/ru_RU';

import XHRUpload from '@uppy/xhr-upload';
import Dashboard from '@uppy/dashboard';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

document.addEventListener('DOMContentLoaded', () => {
  const uploader = document.getElementById('company-card-upload-area');

  if (uploader) {
    const customLocale = Object.assign({}, russianLocale);
    customLocale['strings']['dropPaste'] = 'Перетащите карточку компании для загрузки, или %{browse} через диалог';

    const uppy = Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 1000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.pdf', '.doc', '.docx', '.xls', '.xlsx']
      }
    }).use(Dashboard, {
      inline: true,
      target: '#company-card-upload-area',
      height: 100,
      width: '100%',
      locale: customLocale,
      proudlyDisplayPoweredByUppy: false,
      showLinkToFileUploadResult: false,
      disableThumbnailGenerator: true,
      showSelectedFiles: false,
      hideUploadButton: true,
      hideRetryButton: true
    }).use(
      XHRUpload,
      {
        endpoint: uploader.dataset.url,
        fieldName: 'file',
        bundle: false,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
    );

    uppy
      .on('upload-success', (file, response) => {
        const target = document.getElementById(uploader.dataset.target);
        target.outerHTML = response.body.html;
      })
      .on('upload-error', (file, error, response) => {
        console.log(response.body.errors);
      });
  }
});
