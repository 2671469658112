document.addEventListener('DOMContentLoaded', () => {
  var tabsSwitchers = document.querySelectorAll('li[data-role="tabs-switcher"]');

  tabsSwitchers.forEach(function(tabsSwitcher) {
    tabsSwitcher.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      const activeSwitcher = tabsSwitcher.parentNode.querySelector('.is-active');
      if (activeSwitcher) { activeSwitcher.classList.remove('is-active') };

      tabsSwitcher.classList.add('is-active');

      const target = document.getElementById(tabsSwitcher.dataset.target);
      if (target) {
        const activeTab = target.parentNode.querySelector('.content--product:not(.is-hidden)');
        if (activeTab) { activeTab.classList.add('is-hidden') };
        target.classList.remove('is-hidden');
      }
    });
  })
})
