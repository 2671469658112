document.addEventListener('DOMContentLoaded', () => {
  const imageThumbs = document.querySelectorAll('figure[data-role="product-image-thumb"]');

  imageThumbs.forEach(function(imageThumb) {
    const target = document.getElementById(imageThumb.dataset.target);

    imageThumb.addEventListener('click', (event) => {
      const targetImg = document.getElementById(`${imageThumb.dataset.target}-img`);
      target.removeChild(targetImg);

      const nextImage = new Image();
      nextImage.id = `${imageThumb.dataset.target}-img`;
      nextImage.addEventListener('load', function() {
        target.appendChild(nextImage);
      });
      nextImage.src = imageThumb.dataset.fullImage;

      const activeImageThumbs = document.querySelectorAll('figure[data-role="product-image-thumb"].is-active');
      activeImageThumbs.forEach(function(activeImageThumb) {
        activeImageThumb.classList.remove('is-active');
      });

      imageThumb.classList.add('is-active');
    });
  });
})
