import axios from 'axios';
import formatCurrency from './functions/format-currency';

document.addEventListener('DOMContentLoaded', () => {
  var quantityInputs = document.querySelectorAll('input[data-role="quantity-input"]');

  quantityInputs.forEach(function(quantityInput) {
    const field = quantityInput.closest('[data-role="quantity-input-container"]');
    const decrementButton = field.querySelector('a[data-role="quantity-input-decrement"]');
    const incrementButton = field.querySelector('a[data-role="quantity-input-increment"]');
    const minValue = quantityInput.dataset.minValue;

    quantityInput.addEventListener('change', (event) => {
      const newValue = parseInt(quantityInput.value);
      if (newValue < minValue) { quantityInput.value = minValue; }

      if (quantityInput.dataset.updateUrl) {
        axios.patch(quantityInput.dataset.updateUrl, { quantity: newValue }).then(function (response) {
          const cartItemTotalElement = document.getElementById(quantityInput.dataset.cartItemTotalId);
          if (cartItemTotalElement) {
            const cart_item_data = response.data.cart_item;

            const formattedTotalPrice = formatCurrency(parseInt(cart_item_data.total_price));

            if (cart_item_data.discounted_price === cart_item_data.total_price) {
              cartItemTotalElement.innerHTML = formattedTotalPrice;
            } else {
              const formattedDiscountedPrice = formatCurrency(parseInt(cart_item_data.discounted_price));
              cartItemTotalElement.innerHTML = `<span class="is-strikethrough is-size-7">${formattedTotalPrice}</span><br />${formattedDiscountedPrice}`;
            }
          }

          const event = new CustomEvent('cart-updated', { detail: response.data.cart });
          window.dispatchEvent(event);

          function gtag() { dataLayer.push(arguments); }
          gtag('event', response.data.gtag_event_name, { "items": response.data.gtag_event_items });
        })
      }
    });

    const changeEvent = new Event('change');

    decrementButton.addEventListener('click', (event) => {
      event.preventDefault();
      quantityInput.value = parseInt(quantityInput.value) - 1;
      quantityInput.dispatchEvent(changeEvent);
    });

    incrementButton.addEventListener('click', (event) => {
      event.preventDefault();
      quantityInput.value = parseInt(quantityInput.value) + 1;
      quantityInput.dispatchEvent(changeEvent);
    });
  })
})
