import { getSlider } from 'simple-slider';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('div[data-simple-slider]');

  if (sliders.length > 0) {
    getSlider({
      duration: 0.75,
      delay: 4,
      prop: 'left',
      init: 100,
      show: 0,
      end: -100,
      unit: '%'
    });
  }
})
