import setQueryParam from './functions/set-query-param';
import loadAndReplaceProductsFrame from './functions/load-and-replace-products-frame';

document.addEventListener('DOMContentLoaded', () => {
  const priceRangeSliders = document.querySelectorAll('div[data-role="price-range-slider"]');

  priceRangeSliders.forEach(function(priceRangeSlider) {
    const min = parseInt(priceRangeSlider.dataset.min);
    const max = parseInt(priceRangeSlider.dataset.max);

    let selectionStart = parseInt(priceRangeSlider.dataset.start);
    if (!selectionStart || selectionStart === 0) { selectionStart = min };
    let selectionEnd = parseInt(priceRangeSlider.dataset.end);
    if (!selectionEnd || selectionEnd === 0) { selectionEnd = max };

    const priceRangeStartInput = document.getElementById(priceRangeSlider.dataset.inputRangeStart);
    const priceRangeEndInput = document.getElementById(priceRangeSlider.dataset.inputRangeEnd);

    const slider = noUiSlider.create(priceRangeSlider, {
      start: [selectionStart, selectionEnd],
      connect: true,
      tooltips: false,
      behaviour: 'tap-drag',
      range: {
        'min': min,
        'max': max
      },
      margin: (max - min) / 20,
      format: {
        from: function(number) {
          return Math.round(number);
        },
        to: function(number) {
          return Math.round(number);
        }
      }
    });

    slider.on('update.one', function (values) {
      priceRangeStartInput.value = values[0];
      priceRangeEndInput.value = values[1];
    });

    slider.on('set.one', function (values) {
      let paramsString = window.location.search;
      paramsString = setQueryParam(paramsString, 'min_price', values[0]);
      paramsString = setQueryParam(paramsString, 'max_price', values[1]);
      paramsString = setQueryParam(paramsString, 'page', 1);

      loadAndReplaceProductsFrame(
        priceRangeSlider.dataset.target,
        window.location.pathname + paramsString
      );

      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'products_filter_applied');
    });

    priceRangeStartInput.addEventListener('change', function () {
      slider.set([this.value, null]);
    });

    priceRangeEndInput.addEventListener('change', function () {
      slider.set([null, this.value]);
    });
  })
})
