export default function () {
  window.dataLayer = window.dataLayer || [];

  function gtag() { dataLayer.push(arguments); }

  gtag('js', new Date());
  gtag('config', 'UA-143249651-1');

  const sessionID = document.querySelector('body').dataset.sessionId;
  if (sessionID) {
    gtag('set', {'user_id': sessionID});
  }

  const gaEventElement = document.getElementById('ga-event-data-container');
  if (gaEventElement) {
    const data = gaEventElement.dataset;

    gtag(
      'event',
      data.eventName,
      {
        items: JSON.parse(data.items),
        ...JSON.parse(data.additionalItems)
      }
    );
  }
}
