/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "./../../stylesheets/packs/app.scss";

import "./../app/add-to-cart-button";
import "./../app/contact-email-link";
import "./../app/company-card-upload-area";
import "./../app/company-type-select";
import "./../app/delete-cart-item";
import "./../app/email-selection-listener";
import "./../app/filterable-option-checkboxes";
import "./../app/font-awesome";
import "./../app/modal-close";
import "./../app/navbar-burger";
import "./../app/phone-link";
import "./../app/phone-input";
import "./../app/price-range-slider";
import "./../app/products-order-select";
import "./../app/product-variant-select";
import "./../app/product-image-thumb";
import "./../app/products-display-toggle";
import "./../app/quantity-input";
import "./../app/search-input";
import "./../app/bank-autocomplete-suggestion";
import "./../app/bank-search-input";
import "./../app/company-autocomplete-suggestion";
import "./../app/company-search-input";
import "./../app/tabs-switcher";
import "./../app/partial-loader";
import "./../app/main-slider";

import "./../app/event-listeners/cart-updated";

import setupCSRFToken from "./../app/functions/setup-csrf-token";
import setupGoogleAnalytics from "./../app/functions/setup-google-analytics";
import setupYandexMetrika from "./../app/functions/setup-yandex-metrika";

import Rails from "@rails/ujs";
Rails.start();

window.addEventListener("DOMContentLoaded", setupCSRFToken);
window.addEventListener("DOMContentLoaded", setupGoogleAnalytics);
window.addEventListener("DOMContentLoaded", setupYandexMetrika);
