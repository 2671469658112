document.addEventListener('DOMContentLoaded', () => {
  var contactEmailLinks = document.querySelectorAll('[data-role="contact-email-link"]');

  contactEmailLinks.forEach(function(contactEmailLink) {
    contactEmailLink.addEventListener('click', (event) => {
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'email_selected_or_clicked');
    });
  })
});
