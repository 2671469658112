import axios from 'axios';
import showModal from './functions/show-modal';

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', function(event) {
    const addToCartButton = event.target.closest('[data-role="add-to-cart-button"]');

    if (addToCartButton && event.button === 0) {
      event.preventDefault();

      const url = addToCartButton.dataset.url;
      const quantityInput = document.getElementById(addToCartButton.dataset.quantityInput);
      const quantity = quantityInput ? quantityInput.value : addToCartButton.dataset.quantity;
      const productVariantId = addToCartButton.dataset.productVariantId;

      axios.post(
        url, { quantity, product_variant_id: productVariantId }
      ).then(function (response) {
        const event = new CustomEvent('cart-updated', { detail: response.data.cart });
        window.dispatchEvent(event);

        showModal(response.data.modal_html);

        function gtag() { dataLayer.push(arguments); }
        gtag('event', 'add_to_cart', { "items": response.data.gtag_event_items });

        if (typeof ym !== 'undefined') { ym(54961249, 'reachGoal', 'form_1') };
      });
    }
  }, false);
});
