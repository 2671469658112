document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', function(event) {
    const displayToggle = event.target.closest('a[data-role="products-display-toggle"]');

    if (displayToggle && event.button === 0 && !event.getModifierState('Meta')) {
      event.preventDefault();
      event.stopPropagation();

      if (displayToggle.getAttribute('disabled')) { return }

      const target = document.getElementById(displayToggle.dataset.target);
      if (target) {
        const classPrefix = displayToggle.dataset.classPrefix;
        const fullClassName = classPrefix + displayToggle.dataset.class;

        target.classList.remove(
          ...[...target.classList].filter(className => className.startsWith(classPrefix))
        );
        target.classList.add(fullClassName);

        const activeToggle = displayToggle.closest('ul').querySelector('a.is-active');
        if (activeToggle) {
          activeToggle.classList.remove('is-active');
        }

        displayToggle.classList.add('is-active');

        document.cookie = `products_display_style=${displayToggle.dataset.class}`;
      }
    }
  });
})
