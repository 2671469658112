import axios from 'axios';
import throttle from 'lodash';

document.addEventListener('DOMContentLoaded', () => {
  const searchInputs = document.querySelectorAll('input[data-role="search-input"]');

  searchInputs.forEach(function(searchInput) {
    const form = searchInput.closest('form');
    const searchResultsPreview = form.querySelector('[data-role="search-results-preview"]');

    searchInput.addEventListener('focus', () => {
      form.classList.add('focused');
    });

    searchInput.addEventListener('blur', () => {
      setTimeout(() => { form.classList.remove('focused') }, 100);
    });

    const loadSearchResultsPreview = function () {
      if (searchInput.value.length < 3) {
        searchResultsPreview.innerHTML = '';
      } else {
        axios.post(
          form.action,
          {
            search: {
              query: searchInput.value
            }
          }
        ).then(function (response) {
          searchResultsPreview.innerHTML = response.data.html;
        });
      }
    };

    searchInput.addEventListener('input', _.throttle(loadSearchResultsPreview, 500));

    searchInput.addEventListener('keydown', (e) => {
      if (![13, 27, 38, 40].includes(event.keyCode)) { return };

      if (event.keyCode == 27) {
        searchInput.blur();
        return
      }

      const results = searchResultsPreview.querySelectorAll('li');
      const resultsLength = results.length;
      if (resultsLength === 0) { return };

      const selected = searchResultsPreview.querySelector('li.selected');
      let selectedIndex;
      if (selected) {
        selected.classList.remove('selected');
        selectedIndex = Array.prototype.slice.call(results).indexOf(selected);

        if (event.keyCode == 13) {
          e.preventDefault();
          e.stopPropagation();
          selected.querySelector('a').click();

          return
        }
      } else {
        selectedIndex = -1;
      }

      let nextSelectedIndex;
      if (event.keyCode === 40) {
        nextSelectedIndex = selectedIndex + 1;
        if (nextSelectedIndex >= resultsLength) { nextSelectedIndex = 0 }
      } else if (event.keyCode === 38) {
        nextSelectedIndex = selectedIndex - 1;
        if (nextSelectedIndex < 0) { nextSelectedIndex = resultsLength - 1 }
      }
      results[nextSelectedIndex].classList.add('selected');
    });

    searchResultsPreview.addEventListener('mouseover', (e) => {
      const selected = searchResultsPreview.querySelector('li.selected');
      if (selected) { selected.classList.remove('selected'); };
    });
  })
})
