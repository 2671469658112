import loadAndReplaceProductsFrame from './functions/load-and-replace-products-frame';

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', function(event) {
    const loader = event.target.closest('a[data-role="partial-loader"]');

    if (loader && event.button === 0 && !event.getModifierState('Meta')) {
      event.preventDefault();
      event.stopPropagation();

      if (loader.dataset.disabled === 'true') { return }

      loadAndReplaceProductsFrame(loader.dataset.target, loader.href);
    }
  });
})
