document.addEventListener('DOMContentLoaded', () => {
  var phoneLinks = document.querySelectorAll('[data-role="phone-link"]');

  phoneLinks.forEach(function(phoneLink) {
    phoneLink.addEventListener('click', (event) => {
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'phone_number_clicked');
    });
  })
});
