document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', function(event) {
    const autocompleteSuggestion = event.target.closest('[data-role="bank-autocomplete-suggestion"]');

    if (autocompleteSuggestion) {
      const bankSearchResultsPreview = autocompleteSuggestion.closest('[data-role="bank-search-results-preview"]');
      const dataset = autocompleteSuggestion.dataset;
      const bankJSON = dataset.bankDetails.replace(/\\"/g, '"').replace(/\\\\"/g, '\\"');
      const bankDetails = JSON.parse(bankJSON);

      bankSearchResultsPreview.innerHTML = '';

      const bicInput = document.getElementById(dataset.inputBic);
      if (bicInput) { bicInput.value = bankDetails.bic; }

      const nameInput = document.getElementById(dataset.inputName);
      if (nameInput) { nameInput.value = bankDetails.name; }

      const correspondentAccountInput = document.getElementById(dataset.inputCorrespondentAccount);
      if (correspondentAccountInput) { correspondentAccountInput.value = bankDetails.correspondent_account; }
    };
  });
})
