import axios from 'axios';
import throttle from 'lodash';

document.addEventListener('DOMContentLoaded', () => {
  const searchInputs = document.querySelectorAll('input[data-role="company-search-input"]');

  searchInputs.forEach(function(searchInput) {
    const form = searchInput.closest('form');
    const spinner = form.querySelector('[data-role="company-search-spinner"]');
    const searchResultsPreview = form.querySelector('ul[data-role="company-search-results-preview"]');
    const searchResultsError = form.querySelector('[data-role="company-search-error"]');

    const loadSearchResultsPreview = function () {
      const query = searchInput.value;

      if (/^\d+$/.test(query)) {
        if (query.length !== 10 && query.length !== 12) {
          searchResultsPreview.innerHTML = '';
          return
        }
      } else if (query.length < 5) {
        searchResultsPreview.innerHTML = '';
        return
      };

      spinner.classList.remove('is-hidden');
      searchResultsError.classList.add('is-hidden');

      axios.post(
        form.action,
        {
          company_search: {
            query: searchInput.value
          }
        }
      ).then(function (response) {
        spinner.classList.add('is-hidden');
        searchResultsPreview.innerHTML = response.data.html;
      }).catch(function (err) {
        spinner.classList.add('is-hidden');
        searchResultsError.classList.remove('is-hidden');
      });
    };

    searchInput.addEventListener('input', _.throttle(loadSearchResultsPreview, 1000));

    searchInput.addEventListener('keydown', (e) => {
      if (![13, 27, 38, 40].includes(event.keyCode)) { return };

      if (event.keyCode == 27) {
        searchInput.blur();
        return
      }

      const results = searchResultsPreview.querySelectorAll('li');
      const resultsLength = results.length;
      if (resultsLength === 0) { return };

      const selected = searchResultsPreview.querySelector('li.selected');
      let selectedIndex;
      if (selected) {
        selected.classList.remove('selected');
        selectedIndex = Array.prototype.slice.call(results).indexOf(selected);

        if (event.keyCode == 13) {
          e.preventDefault();
          e.stopPropagation();
          selected.click();

          return
        }
      } else {
        selectedIndex = -1;
      }

      let nextSelectedIndex;
      if (event.keyCode === 40) {
        nextSelectedIndex = selectedIndex + 1;
        if (nextSelectedIndex >= resultsLength) { nextSelectedIndex = 0 }
      } else if (event.keyCode === 38) {
        nextSelectedIndex = selectedIndex - 1;
        if (nextSelectedIndex < 0) { nextSelectedIndex = resultsLength - 1 }
      }
      results[nextSelectedIndex].classList.add('selected');
    });

    searchResultsPreview.addEventListener('mouseover', (e) => {
      const selected = searchResultsPreview.querySelector('li.selected');
      if (selected) { selected.classList.remove('selected'); };
    });
  })
})
