import setQueryParam from './functions/set-query-param';
import loadAndReplaceProductsFrame from './functions/load-and-replace-products-frame';

document.addEventListener('DOMContentLoaded', () => {
  const filters = document.querySelectorAll('div[data-role="filterable-option-checkboxes"]');

  filters.forEach(function(filter) {
    const checkboxes = filter.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(function(checkbox) {
      checkbox.addEventListener('change', (event) => {
        let filterValues = [];

        checkboxes.forEach(function(checkbox) {
          if (checkbox.checked) {
            filterValues.push(checkbox.value);
          }
        });

        let paramsString = window.location.search;
        paramsString = setQueryParam(
          paramsString,
          encodeURIComponent(`filters[${filter.dataset.id}]`),
          filterValues.join(',')
        );
        paramsString = setQueryParam(paramsString, 'page', 1);

        loadAndReplaceProductsFrame(
          filter.dataset.target,
          window.location.pathname + paramsString
        );

        function gtag() { dataLayer.push(arguments); }
        gtag('event', 'products_filter_applied');
      });
    });
  })
})
