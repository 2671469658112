document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', function(event) {
    const closer = event.target.closest('[data-role="modal-close"]');

    if (closer && event.button === 0 && !event.getModifierState('Meta')) {
      event.preventDefault();
      event.stopPropagation();

      const modal = closer.closest('.modal');
      modal.parentNode.removeChild(modal);

      document.documentElement.classList.remove('is-clipped');
    }
  });
})
