document.addEventListener('DOMContentLoaded', () => {
  var productVariantSelects = document.querySelectorAll('select[data-role="product-variant-select"]');

  productVariantSelects.forEach(function(productVariantSelect) {
    productVariantSelect.addEventListener('change', (event) => {
      const selectedOption = productVariantSelect.options[productVariantSelect.selectedIndex];
      window.location.href = selectedOption.dataset.url;
    });
  })
})
