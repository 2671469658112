import axios from 'axios';

export default function (targetId, url) {
  const target = document.getElementById(targetId);
  target.classList.add('is-loading');

  axios.get(url).then(function (response) {
    history.pushState({ frame: url }, url, url);
    const targetContainer = target.parentNode;
    targetContainer.removeChild(target);
    targetContainer.innerHTML = targetContainer.innerHTML + response.data.html;
    target.classList.remove('is-loading');

    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'view_item_list', { "items": response.data.gtag_event_items });

    if (targetContainer.offsetTop - window.pageYOffset < 100) {
      window.scrollTo(0, targetContainer.offsetTop - 100);
    }
  });
};
