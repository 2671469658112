document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', function(event) {
    const autocompleteSuggestion = event.target.closest('[data-role="company-autocomplete-suggestion"]');

    if (autocompleteSuggestion) {
      const companySearchResultsPreview = autocompleteSuggestion.closest('[data-role="company-search-results-preview"]');
      const dataset = autocompleteSuggestion.dataset;
      const companyJSON = dataset.companyDetails.replace(/\\"/g, '"').replace(/\\\\"/g, '\\"');
      const companyDetails = JSON.parse(companyJSON);

      companySearchResultsPreview.innerHTML = '';

      const typeSelect = document.getElementById(dataset.selectType);
      if (typeSelect) {
        typeSelect.value = companyDetails.type;
        const event = new Event('change');
        typeSelect.dispatchEvent(event);
      }

      const fullNameInput = document.getElementById(dataset.inputFullName);
      if (fullNameInput) { fullNameInput.value = companyDetails.full_name; }

      const shortNameInput = document.getElementById(dataset.inputShortName);
      if (shortNameInput) { shortNameInput.value = companyDetails.short_name; }

      const addressInput = document.getElementById(dataset.inputAddress);
      if (addressInput) { addressInput.value = companyDetails.address; }

      const innInput = document.getElementById(dataset.inputInn);
      if (innInput) { innInput.value = companyDetails.inn; }

      const kppInput = document.getElementById(dataset.inputKpp);
      if (kppInput) { kppInput.value = companyDetails.kpp; }

      const ogrnInput = document.getElementById(dataset.inputOgrn);
      if (ogrnInput) { ogrnInput.value = companyDetails.ogrn; }

      const managementNameInput = document.getElementById(dataset.inputManagementName);
      if (managementNameInput) { managementNameInput.value = companyDetails.management_name; }

      const managementPositionInput = document.getElementById(dataset.inputManagementPosition);
      if (managementPositionInput) { managementPositionInput.value = companyDetails.management_position; }
    };
  });
})
