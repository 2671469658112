import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  var deleteButtons = document.querySelectorAll('[data-role="delete-cart-item"]');

  deleteButtons.forEach(function(deleteButton) {
    deleteButton.addEventListener('click', (event) => {
      axios.delete(deleteButton.dataset.url).then(function (response) {
        const tr = deleteButton.closest('tr');
        const tbody = tr.parentNode;
        tbody.removeChild(tr);

        if (tbody.querySelectorAll('tr').length == 0) {
          const table = tbody.closest('table');
          table.parentNode.removeChild(table);

          document.getElementById(deleteButton.dataset.emptyMessageId).classList.remove('is-hidden');
        }

        const event = new CustomEvent('cart-updated', { detail: response.data.cart });
        window.dispatchEvent(event);

        function gtag() { dataLayer.push(arguments); }
        gtag('event', 'remove_from_cart', { "items": response.data.gtag_event_items });
      });
    });
  })
});
