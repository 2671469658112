document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('mouseup', (event) => {
    let selection = '';

    if (window.getSelection) {
      selection = window.getSelection();
    } else if (document.selection) {
      selection = document.selection.createRange();
    }

    if (selection.toString().includes('info@softopt.ru')) {
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'email_selected_or_clicked');
    }
  });
});
