document.addEventListener('DOMContentLoaded', () => {
  const companyTypeSelects = document.querySelectorAll('select[data-role="company-type-select"]');

  companyTypeSelects.forEach(function(companyTypeSelect) {
    companyTypeSelect.addEventListener('change', function() {
      const kppField = document.getElementById(companyTypeSelect.dataset.kppField);

      if (companyTypeSelect.value === 'individual') {
        kppField.classList.add('is-hidden');
      } else {
        kppField.classList.remove('is-hidden');
      }
    });

    const event = new Event('change');
    companyTypeSelect.dispatchEvent(event);
  })
})
