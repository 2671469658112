import formatCurrency from './../functions/format-currency';

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('cart-updated', function(event) {
    const data = event.detail;
    const discountedPriceStr = formatCurrency(parseFloat(data.discounted_price));
    const totalPriceStr = formatCurrency(parseFloat(data.total_price));
    const discountStr = formatCurrency(parseFloat(data.discount));

    const cartDiscountedPriceElements = document.querySelectorAll('[data-role="cart-discounted-price"]');
    cartDiscountedPriceElements.forEach(function(cartDiscountedPriceElement) {
      cartDiscountedPriceElement.innerHTML = discountedPriceStr;
    });

    const cartTotalPriceElements = document.querySelectorAll('[data-role="cart-total-price"]');
    cartTotalPriceElements.forEach(function(cartTotalPriceElement) {
      cartTotalPriceElement.innerHTML = totalPriceStr;
    });

    const cartDiscountElements = document.querySelectorAll('[data-role="cart-discount"]');
    cartDiscountElements.forEach(function(cartDiscountElement) {
      cartDiscountElement.innerHTML = discountStr;
    });
  });
});
